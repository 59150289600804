<template>
  <div>
    <v-card :loading="loading">
      <v-card-title>
        {{ $t('report_assessment2') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="exportLoading || loading"
          :loading="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-row class="mx-2 mt-2">
        <v-col
          cols="12"
          md="6"
          lg="3"
        >
          <v-dialog
            ref="dateStart"
            v-model.trim="isShowDate1"
            :return-value.sync="start"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model.trim="start"
                :label="$t('date')"
                outlined
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim="start"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.dateStart.save(start)"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="isShowDate1 = false"
              >
                {{ $t("cancel") }}
              </v-btn>

              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
        >
          <v-dialog
            ref="dateEnd"
            v-model.trim="isShowDate2"
            :return-value.sync="end"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model.trim="end"
                :label="$t('to')"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim="end"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>

              <v-btn
                text
                color="primary"
                @click="$refs.dateEnd.save(end)"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="isShowDate2 = false"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
        >
          <v-text-field
            v-model="searchtext"
            outlined
            dense
            :label="$t('search')"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
        >
          <v-btn
            color="info"
            outlined
            @click="panel.length == 0 ? all(): panel = []"
          >
            {{ panel.length == 0 ? $t('showDetailAll') : $t('close') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-expansion-panels
        v-model="panel"
        focusable
        multiple
      >
        <v-expansion-panel
          v-for="(item,i) in dataList"
          :key="i"
        >
          <v-expansion-panel-header class="text-lg">
            #{{ item.forms_id }}  {{ item.forms_fullname }} ({{ item.forms_tel }}) <v-spacer></v-spacer>
            {{ item.forms_create | sumdate }} {{ item.forms_type }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row
              v-masonry=""
              transition-duration="0.3s"
              item-selector=".item"
            >
              <v-col
                v-for="(n,index) in item.data_course_group"
                :key="index"
                v-masonry-tile
                class="item"
                cols="12"
                md="4"
              >
                <p class="font-weight-semibold">
                  <v-icon>{{ icons.mdiSquareMedium }}</v-icon> {{ n.forms_course_group_name }}
                </p>
                <div
                  v-for="(x,a) in n.forms_course"
                  :key="a"
                  class="pl-7"
                >
                  <v-checkbox
                    v-model="x.forms_map_course_score"
                    :label="x.forms_course_name"
                    :true-value="1"
                    :false-value="0"
                    color="primary"
                    readonly
                    hide-details
                  ></v-checkbox>
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row
              v-masonry=""
              transition-duration="0.3s"
              item-selector=".item"
            >
              <v-col
                v-for="(t,q) in item.data_question_group"
                :key="q"
                v-masonry-tile
                cols="12"
                md="6"
                class="item"
              >
                <p class="font-weight-semibold">
                  <v-icon>{{ icons.mdiSquareMedium }}</v-icon>  {{ t.forms_question_group_name }}
                </p>
                <div
                  v-for="(h,k) in t.forms_question"
                  :key="k"
                >
                  <p class="font-weight-semibold pl-5 mt-0 pb-0 mb-0">
                    <v-icon>{{ icons.mdiCircleMedium }}</v-icon>  {{ h.forms_question_name }}
                  </p>
                  <v-radio-group
                    v-model="h.forms_map_question_score"
                    class="pt-0 pl-8 mt-0"
                    readonly
                    row
                  >
                    <v-radio
                      v-for="n in 5"
                      :key="n"
                      :label="`${n}`"
                      :value="String(n)"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>

            <p class="font-weight-bold mb-0">
              <v-icon>{{ icons.mdiSquareMedium }}</v-icon> {{ $t('otherMore') }}
            </p>
            <p class="pl-8 mt-0">
              {{ item.forms_comment }}
            </p>
            <v-divider class="my-2"></v-divider>

            <p class="font-weight-bold mb-0">
              <v-icon>{{ icons.mdiSquareMedium }}</v-icon> {{ $t('branceMore') }}
            </p>
            <p class="pl-8 mt-0">
              {{ item.forms_open_zone }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import useDocsReport2 from './useDocsReport2'
import { mdiCircleMedium, mdiSquareMedium } from '@mdi/js'
import { sumdate } from '@/plugins/filters'

export default {
  filters: {
    sumdate,
  },
  setup() {
    return {
      ...useDocsReport2(),
      icons: {
        mdiCircleMedium,
        mdiSquareMedium,
      },
    }
  },
}
</script>
