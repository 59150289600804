import assessment from '@/api/WebsiteManageMent/assessment'
import { i18n } from '@/plugins/i18n'
import { removeComma } from '@/views/reports/useExcel'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useDocsReport2() {
  const moment = require('moment')
  const XLSX = require('xlsx')
  const isShowDate1 = ref(false)
  const isShowDate2 = ref('')
  const start = ref(moment(new Date()).format('YYYY-MM-DD'))
  const end = ref(moment(new Date()).format('YYYY-MM-DD'))
  const searchtext = ref('')
  const dataList = ref([])
  const panel = ref([])
  const items = ref(0)
  const loading = ref(false)
  const exportLoading = ref(false)

  onMounted(() => {
    fetchDataTable()
  })

  const fetchDataTable = () => {
    loading.value = true
    assessment
      .assessmentReport({
        start: start.value,
        end: end.value,
        searchtext: searchtext.value,
      })
      .then(res => {
        dataList.value = res
        items.value = res.length
        loading.value = false
      })
  }

  const all = () => {
    panel.value = [...Array(items.value).keys()].map((k, i) => i)
  }

  const exportExcel = async () => {
    exportLoading.value = true
    const data = []
    for (const item of dataList.value) {
      // const row = {}
      const temp = []
      const temp2 = []
      for (const subItem of item.data_course_group) {
        let subRow = []

        subRow = subItem.forms_course.filter(x => x.forms_map_course_score == 1)
        subRow = subRow.map(x => x.forms_course_name)

        temp.push(subRow)
      }
      for (let i = 0; i < temp.length; i++) {
        item[`data_course_group${i + 1}`] = temp[i].toString()
      }
      let count = 0
      for (const subItem2 of item.data_question_group) {
        let subRow2 = []
        subRow2 = subItem2.forms_question.map(x => x.forms_map_question_score)
        temp2.push(subRow2)
        subRow2.forEach(element2 => {
          count++
          item[`data_question_group${count}`] = element2
        })
      }
      data.push({ ...item, data_course_group: temp })
    }

    const dataExport = await removeComma(JSON.parse(JSON.stringify(data)))
    const fileName = `${i18n.t('assessment2')}.xlsx`

    //* เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx *//
    /*  รายงานวิเคราะห์รายการตรวจ */
    const Heading = [
      [`${i18n.t('assessment2')} `],
      [
        `${i18n.t('a_number')}`,
        `${i18n.t('firstname_lastname')}`,
        `${i18n.t('email')}`,
        `${i18n.t('tel')}`,
        `${i18n.t('formatReciveService')}`,
      ],
      ['', '', '', '', ''],
    ]

    // console.log('dataList.value', dataList.value[0].data_course_group)
    for (let x = 0; x < dataList.value[0].data_course_group.length; x++) {
      if (x == 0) {
        Heading[1].push(`${i18n.t('promotionDiscount')}`)
      } else {
        Heading[1].push('')
      }
    }

    dataList.value[0].data_course_group.forEach(item => {
      Heading[2].push(item.forms_course_group_name)
    })

    // dataList.value[0].data_question_group.forEach(item => {
    //   Heading[1].push(item.forms_question_group_name)
    // })

    for (let x = 0; x < dataList.value[0].data_question_group.length; x++) {
      dataList.value[0].data_question_group[x].forms_question.forEach((element, index) => {
        if (index == 0) {
          Heading[1].push(dataList.value[0].data_question_group[x].forms_question_group_name)
        } else {
          Heading[1].push('')
        }
        Heading[2].push(element.forms_question_name)
      })

      // for (let a = 0; a < dataList.value[0].data_question_group[x].forms_question.length; a++) {
      //   Heading[1].push('')
      // }
    }
    Heading[2].push(`${i18n.t('otherMore')}`)
    Heading[2].push(`${i18n.t('branceMore')}`)
    Heading[2].push(`${i18n.t('date')}`)
    Heading[2].push(`${i18n.t('tag')}`)

    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: Heading[2].length } },
      { s: { r: 1, c: 5 }, e: { r: 1, c: dataExport[0].data_course_group.length + 4 } },
    ]
    let startCol = dataExport[0].data_course_group.length + 5
    let endCol = 0
    dataExport[0].data_question_group.forEach(itemxx => {
      endCol = startCol + itemxx.forms_question.length - 1
      merge.push({
        s: { r: 1, c: startCol },
        e: { r: 1, c: endCol },
      })
      startCol = endCol + 1
    })
    dataExport.forEach(value1 => {
      delete value1.customer_tags
      delete value1.forms_open_zone
      delete value1.forms_comment
      delete value1.forms_create
      delete value1.data_course_group
      delete value1.data_question_group
    })

    dataList.value.forEach((element3, index) => {
      dataExport[index].forms_comment = element3.forms_comment
      dataExport[index].forms_open_zone = element3.forms_open_zone
      dataExport[index].forms_create = element3.forms_create
      dataExport[index].customer_tags = String(element3.customer_tags)
    })

    const ws = XLSX.utils.aoa_to_sheet(Heading)
    XLSX.utils.sheet_add_json(ws, dataExport, {
      header: ['forms_id', 'forms_fullname', 'forms_email', 'forms_tel', 'forms_type'],
      skipHeader: true,
      origin: -1,
    })
    const wb = XLSX.utils.book_new()
    const wscols = [
      { wch: 8 },
      { wch: 30 },
      { wch: 30 },
      { wch: 15 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ]
    ws['!merges'] = merge
    ws['!cols'] = wscols
    XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('report_accounts_receivable')}`)

    /* พิมร์ files */

    XLSX.writeFile(wb, fileName)

    setTimeout(() => {
      exportLoading.value = false
    }, 1500)
  }

  watch([start, end, searchtext], () => {
    fetchDataTable()
  })

  return {
    exportExcel,
    exportLoading,
    loading,
    all,
    panel,
    isShowDate1,
    isShowDate2,
    start,
    end,
    searchtext,
    dataList,
  }
}
